import { OpenAI } from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

// Define Argot's persona in the system message
const argotPersona = {
  role: "system",
  content: `You are Argot, a friend to the user.
  Don't ask a question in every response.
  Only return 1 list max in a response`
};

export const generateResponse = async (message, conversationHistory = []) => {
  try {
    // Add the user's latest message to the conversation history
    const messages = [...conversationHistory, { role: "user", content: message }];

    // Include Argot's persona and conversation history in the request
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo", // or "gpt-4" if you have access
      messages: [argotPersona, ...messages],
    });

    const responseText = response.choices[0].message.content;
    console.log(responseText);

    // Add Argot's response to the conversation history
    conversationHistory.push({ role: "assistant", content: responseText });

    return responseText;
  } catch (error) {
    console.error("Error generating response:", error);
    throw error;
  }
};
