import React from "react";
import { BrowserRouter, Route, Routes} from "react-router-dom";
// features
import Home from "./features/Home";
import InvalidPath from "./features/InvalidPath";
// components
import ChatComponent from "./components/ChatComponent";
// styles
import './styles/LightModeVars.css'
import './styles/DarkModeVars.css'
import './styles/ButtonStyle.css'

export default function App() {
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}>
                    <Route index element={<ChatComponent />} />
                    <Route path="error" element={<InvalidPath />} />
                    <Route path="*" element={<InvalidPath />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}