import React from "react";
import "../styles/HelpPopUpStyle.css";

const HelpPopUp = ({show, onClose, children}) => {
    if (!show) {
        return null;
    }

    return (
        <div className="help-overlay" onClick={onClose}>
            <div className="help-content" onClick={(e) => e.stopPropagation()}>
                <h3>Helpful Guide</h3>
                <div className="help-message">
                    <p>
                        Argot is a polyglot who can speak in <strong>100+ available languages</strong>, so you
                        can practice speaking new languages with organic conversation!
                        The following information is provided to ensure you have
                        the best experience with Argot - <a href="https://www.youtube.com/watch?v=5mEBIGFfVgk">User Tutorial</a><br></br>
                    </p>
                    <ul>
                        <li>
                            Please use Chrome Browser & headphones/earphones with microphone when speaking to Argot
                        </li>
                        <li>
                            Each time you finish speaking, please be respectful 
                            and let Argot finish speaking too. Once finished speaking,&nbsp;Argot will be ready to listen.
                            If Argot isn't listening after a few seconds, feel free to
                            give Argot a nudge by clicking "Speak with Argot"!
                        </li>
                        <li>
                            If you can't see your required language, try adding a voice:<br></br>
                            &emsp;&emsp;<a href="https://support.microsoft.com/en-gb/topic/download-languages-and-voices-for-immersive-reader-read-mode-and-read-aloud-4c83a8d8-7486-42f7-8e46-2b0fdf753130">Win OS</a>
                            &nbsp;- Settings &#129170; Time & Language &#129170; Speech &#129170; Manage Voices &#129170; Add voices <br></br>
                            &emsp;&emsp;<a href="https://support.apple.com/en-gb/guide/mac-help/mchlp2290/mac">Mac OS</a>
                            &nbsp;- menu &#129170; System Settings &#129170; Accessibility &#129170; Spoken Content &#129170; System Voice<br></br>
                            See <a href="https://cloud.google.com/speech-to-text/docs/speech-to-text-supported-languages">available languages</a>
                            &nbsp;and accents that Argot will understand - if you have the voice on your machine.
                        </li>
                        <li>
                            Please note that Argot won't remember what you talked about previously
                            when you close a browser.&nbsp;Argot will get this update with growing demand.
                        </li>
                    </ul>
                    <p className="closing-help-guide-text">
                        Enjoy!<br></br><br></br><br></br>
                        Other issue? please contact <a href="https://www.illack.co.uk">Argot's creator</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HelpPopUp;