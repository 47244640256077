import React, { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "../styles/SpeechRecStyle.css";

const SpeechRecognitionComponent = ({ onSpeechEnd, language }) => {
    const { transcript, listening, resetTranscript } = useSpeechRecognition();
    const [isProcessing, setIsProcessing] = useState(false);
    const [canListen, setCanListen] = useState(true); // New state to control if listening can be started

    useEffect(() => {
        if (!listening && transcript && !isProcessing) {
            setIsProcessing(true);
            onSpeechEnd(transcript)
                .finally(() => {
                    resetTranscript();
                    setIsProcessing(false);
                    // Now we don't automatically start listening
                    setCanListen(true); // Re-enable listening once the response is processed
                });
        }

        // If SpeechRecognition stops and there's no transcript (user didn't say anything), re-enable the button
        if (!listening && !transcript && !isProcessing) {
            setCanListen(true); // Re-enable the button after stop listening without a transcript
        }
    }, [transcript, listening, onSpeechEnd, resetTranscript, isProcessing]);

    const startListening = () => {
        if (canListen) {
            setCanListen(false); // Prevent listening again until the next button click or after response
            SpeechRecognition.startListening({ continuous: false, language });
        }
    };

    return (
        <div className="SpeechRecognitionComponent">
            <h5 className="ConversationTitle">CONVERSATION</h5>
            <button
                id='start-speech-button'
                onClick={startListening}
                disabled={listening || isProcessing || !canListen} // Disable button if processing or not ready
            >
                {listening ? "Listening..." : isProcessing ? "Processing..." : "Speak with Argot"}
            </button>
            <p>{transcript}&nbsp;</p>
        </div>
    );
};

export default SpeechRecognitionComponent;
