import React, { useState, useEffect } from "react";
import {Container, Row, Col} from "react-bootstrap";
import '../styles/NavBarStyle.css';

const NavBar = () => {
    const lightArgotLogo = process.env.PUBLIC_URL + '/images/argot-logos/argot-black-text-logo.png'
    const darkArgotLogo = process.env.PUBLIC_URL + '/images/argot-logos/argot-green-text-logo.png'

    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        // Check the initial class name and update the state
        setIsDarkMode(document.body.classList.contains('dark'));

        // Optionally, add an event listener for theme change if the class changes dynamically
        const observer = new MutationObserver(() => {
            setIsDarkMode(document.body.classList.contains('dark'));
        });

        // Observe changes to the class attribute on the body element
        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        // Cleanup the observer when the component unmounts
        return () => observer.disconnect();
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col className="NavBarCol">
                </Col>
                <Col className="NavBarCol">
                    <img src={isDarkMode ? darkArgotLogo : lightArgotLogo} alt="argot-speechpal-logo" />
                </Col>
                <Col className="NavBarCol">
                </Col>
            </Row>
        </Container>
    )
}

export default NavBar;