import React, { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Import a Prism theme (dark/coy are examples)
import SpeechRecognitionComponent from "./SpeechRecognitionComponent";
import ThemeToggle from "./ThemeToggle";
import { generateResponse } from "../services/OpenAiService";
import '../styles/ChatStyle.css';
import { useNavigate } from "react-router-dom";

const ChatComponent = () => {
    const [conversation, setConversation] = useState([]);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [language, setLanguage] = useState("en-US");
    const navigate = useNavigate();
    const endOfMessagesRef = useRef(null);
    const listRegex = /(\d+\.\s*[^\n]+|[-•]\s*[^\n]+)/g;  // Handles lists with or without space after the period



    useEffect(() => {
        const loadVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            setVoices(availableVoices);

            if (availableVoices.length > 0) {
                setSelectedVoice(availableVoices[0]);
                setLanguage(availableVoices[0].lang);
            }
        };

        loadVoices();
        window.speechSynthesis.onvoiceschanged = loadVoices;
    }, []);

    useEffect(() => {
        // Scroll to the end of the messages whenever the conversation updates
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation]);

    const handleSpeechEnd = async (transcript) => {
        setConversation((prev) => [...prev, { sender: "user", message: transcript }]);

        try {
            const openAIResponse = await generateResponse(transcript, conversationHistory);

            setConversation((prev) => [...prev, { sender: "bot", message: openAIResponse }]);

            setConversationHistory((prev) => [
                ...prev,
                { role: "user", content: transcript },
                { role: "assistant", content: openAIResponse },
            ]);

            // Extract only non-code text for speech synthesis
            const nonCodeText = extractNonCodeListText(openAIResponse);

            const utterance = new SpeechSynthesisUtterance(nonCodeText);
            utterance.voice = selectedVoice;
            utterance.lang = selectedVoice.lang;
            utterance.rate = 0.9;

            utterance.onend = () => {
                startListening();
            };
            window.speechSynthesis.speak(utterance);

        } catch (error) {
            navigate("/error");
        }

    };

    const startListening = () => {
        document.querySelector('button').click();
    };

    const handleVoiceChange = (event) => {
        const voice = voices.find((v) => v.name === event.target.value);
        setSelectedVoice(voice);
        setLanguage(voice.lang);
    };

    // Function to parse and render lists (including inline numbered and bulleted lists)
    const renderList = (text) => {
        // Split the text into individual lines based on newline characters
        const listItems = text.split('\n').map(item => item.trim()).filter(item => item); // Filter out empty strings

        return (
            <ul>
                {listItems.map((item, index) => {
                    // Use regex to check if the item matches the listRegex for numbered lists
                    const isNumberedList = /^\d+\.\s/.test(item);
                    
                    // If it's a numbered list, remove the leading number and the period
                    const cleanedItem = isNumberedList 
                        ? item.replace(/^\d+\.\s*/, '').trim()  // Remove leading numbers and whitespace
                        : item.replace(/^[•-]\s*|\s*$/, '').trim(); // Remove bullet points or hyphens and whitespace

                    return (
                        <li key={index}>
                            {cleanedItem} {/* Render cleaned item */}
                        </li>
                    );
                })}
            </ul>
        );
    };

    // Updated splitContentAtColon function
    const splitContentAtColon = (content) => {
        const firstColonIndex = content.indexOf(':');
        const lastNewLineIndex = content.lastIndexOf('\n');
    
        if (firstColonIndex !== -1) {
            const firstString = content.slice(0, firstColonIndex + 1).trim(); // First sentence up to and including the first colon
            let lastString = '';
    
            // Check if there's a newline after the first colon
            if (lastNewLineIndex !== -1 && lastNewLineIndex > firstColonIndex) {
                lastString = content.slice(lastNewLineIndex + 1).trim(); // Last sentence after the last newline
    
                // If lastString is part of a list, set it to empty
                if (listRegex.test(lastString)) {
                    lastString = ''; // Consider this a list and exclude it from non-list content
                }
            } else {
                // If no newline, consider the entire content as the lastString
                lastString = content.slice(firstColonIndex + 1).trim();
    
                // Check if the lastString is a single list item (like "10.Alexander")
                if (listRegex.test(lastString)) {
                    lastString = ''; // Treat it as a list item and exclude from regular text
                }
            }
    
            // If firstString and lastString are the same, return only firstString
            return firstString === lastString ? [firstString, ''] : [firstString, lastString];
        }
    
        return [content, '']; // If no colon is found, return the original content and an empty string
    };
    
    
    
    const renderMessage = (message) => {
        const codeRegex = /```(\w+)?\n([\s\S]*?)```/g; // Regular expression to match code blocks
        const segments = [];
        let lastIndex = 0;
        let match;
    
        while ((match = codeRegex.exec(message)) !== null) {
            if (match.index > lastIndex) {
                segments.push({
                    type: 'text',
                    content: message.slice(lastIndex, match.index)
                });
            }
    
            segments.push({
                type: 'code',
                content: match[2],
                prog_lang: match[1]
            });
    
            lastIndex = codeRegex.lastIndex;
        }
    
        if (lastIndex < message.length) {
            segments.push({
                type: 'text',
                content: message.slice(lastIndex)
            });
        }
    
        return segments.map((segment, index) => {
            const content = segment.content.trim();
    
            // Check for lists within the content
            if (listRegex.test(content)) {
                // If the whole content is a list, just render it as is
                if (content.match(listRegex).length === content.split('\n').length) {
                    return (
                        <div key={index} style={{ margin: "5px 0" }}>
                            {renderList(content)}
                        </div>
                    );
                }
    
                const [firstString, lastString] = splitContentAtColon(content);
                return (
                    <div key={index} style={{ margin: "5px 0" }}>
                        <div>{firstString}</div>
                        {renderList(content.match(listRegex).join('\n'))} {/* Render the list as is */}
                        <div>{lastString}</div>
                    </div>
                );
            }
    
            if (segment.type === 'code') {
                return (
                    <div className="code-block" key={index}>
                        <p>{segment.prog_lang}</p>
                        <SyntaxHighlighter
                            language={segment.prog_lang}  // Language of code block e.g., "python", "javascript"
                            style={vs}  // You can swap out `vs` for other themes
                            showLineNumbers
                            customStyle={{
                                borderRadius: '8px',
                                fontSize: '0.9rem',
                                padding: '8px',
                                marginTop: '4px'
                            }}
                        >
                            {segment.content.trim()}
                        </SyntaxHighlighter>
                    </div>
                );
            }
    
            return <span key={index}>{segment.content}</span>;
        });
    };

    // Function to extract non-code text from the response
    const extractNonCodeListText = (message) => {
        const codeRegex = /```([\s\S]*?)```/g;
        const listRegex = /(?:\d+\.\s*|[-•]\s*)([^\n]+?)(?=,|\n|$)/g;
    
        let nonCodeText = '';
        let lastIndex = 0;
        let match;
    
        // Step 1: Remove code blocks from the text
        while ((match = codeRegex.exec(message)) !== null) {
            if (match.index > lastIndex) {
                nonCodeText += message.slice(lastIndex, match.index);
            }
            lastIndex = codeRegex.lastIndex;
        }
    
        // Append any remaining text after the last code block
        if (lastIndex < message.length) {
            nonCodeText += message.slice(lastIndex);
        }
    
        // Step 2: Handle list content and split at colons
        const [firstString, secondString] = splitContentAtColon(nonCodeText, listRegex);
    
        // Combine firstString and secondString for the final result
        return `${firstString} ${secondString}`.trim();
    };

    return (
        <>
            <div className="ChatComponent">
                <div className="LangSelect">
                    <label htmlFor="voice-select">LANGUAGE&nbsp;&nbsp;</label>
                    <select id="voice-select" value={selectedVoice?.name} onChange={handleVoiceChange}>
                        {voices.map((voice) => (
                            <option key={voice.name} value={voice.name}>
                                {`${voice.name} (${voice.lang})`}
                            </option>
                        ))}
                    </select>
                </div>
                <SpeechRecognitionComponent onSpeechEnd={handleSpeechEnd} language={language} />
                <div className="Conversation">
                    {conversation.map((msg, index) => (
                        <div style={{padding:"10px"}} id={msg.sender === "user" ? "user-message" : "argot-message"} key={index}>
                            <strong>{msg.sender === "user" ? "you" : "ARGOT"}</strong>
                            <br></br>
                            {renderMessage(msg.message)}
                        </div>
                    ))}
                    <div ref={endOfMessagesRef}></div>
                </div>
            </div>
            <ThemeToggle />
        </>
    );
};

export default ChatComponent;
