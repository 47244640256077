import React, { useEffect, useState } from "react";
import "../styles/InvalidPathStyle.css"; 

export default function InvalidPath() {

    const [theme, setTheme] = useState("light");

    // Load theme from localStorage (optional)
    useEffect(() => {
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme) {
        setTheme(savedTheme);
        document.body.className = savedTheme; // Apply saved theme to body
        }
    }, []);
    
    return (
        <div className="InvalidPath">
            <p className="invalid-message">
                Uh Oh, something went wrong!<br></br>
                Please contact Argot's creator or try again later
            </p>
        </div>
    );
}