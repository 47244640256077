import React, { useState, useEffect } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../styles/ThemeToggleStyle.css";
import HelpPopUp from "./HelpPopUp";

export default function ThemeToggle() {
    const [theme, setTheme] = useState("light");
    const [showModal, setShowModal] = useState(false);

    // Load theme from localStorage (optional)
    useEffect(() => {
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme) {
        setTheme(savedTheme);
        document.body.className = savedTheme; // Apply saved theme to body
        }
    }, []);

    // To show the modal when the session starts, use useEffect
    useEffect(() => {
        // Automatically show the modal when the component mounts
        setShowModal(true);
    }, []);

    // To handle the modal open/close via a button click
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Toggle between light and dark modes
    const toggleTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        document.body.className = newTheme; // Apply new theme to body
        localStorage.setItem("theme", newTheme); // Save the theme in localStorage
    };

    return (
        <div className="ThemeToggle">
            <button className="ThemeToggleButton" onClick={toggleTheme}>
                {theme === "light" ? 
                    (<i className="bi bi-moon"></i>) : (<i className="bi bi-brightness-high"></i>)
                }
            </button>
            <a href="https://www.illack.co.uk">
                <button className="ContactButton">Contact</button>
            </a>
            <button className="HelpButton" onClick={handleShowModal}>Help</button>
            <HelpPopUp show={showModal} onClose={handleCloseModal} />
        </div>
    );
}
